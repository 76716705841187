import { useEffect, useMemo, useState } from "react";

import { useDirectory } from "../api";

import { AccountCircleOutlined } from "@mui/icons-material";

import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  MenuItem,
  Button,
  Typography,
  Toolbar,
  Menu,
  styled,
} from "@mui/material";
import { Directory } from "../api/types";
import { Link, useNavigate } from "react-router-dom";

const Icon = styled(AccountCircleOutlined)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const Offset = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const TitleLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  textDecoration: "inherit",
  display: "contents",
}));

interface Props {
  directory: string | null;
  setDirectory: (id: string) => void;
}

export const TopBar = ({ directory, setDirectory }: Props) => {
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const [, , directories, loadDirectory] = useDirectory();

  useEffect(() => {
    if (isAuthenticated === true) {
      loadDirectory();
    }
  }, [isAuthenticated, loadDirectory]);

  const handleDirectoryChange = (d: string) => {
    navigate("/");
    setDirectory(d);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <TitleLink to="/">
          <Icon />
          <Title variant="h6"> Volumental Identity</Title>
        </TitleLink>
        {isAuthenticated && (
          <>
            <DirectorySelector
              directories={directories}
              value={directory}
              onSelect={handleDirectoryChange}
            />
            <Offset />
            <Button
              onClick={() => {
                navigate("/developer");
              }}
              color="inherit"
            >
              Developer
            </Button>
            |
            <Button
              onClick={() =>
                logout({
                  returnTo: window.location.origin + "/login",
                })
              }
              color="inherit"
            >
              Logout
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

interface DirectorySelectorProps {
  directories: Directory[];
  value: string | null;
  onSelect: (directory: string) => void;
}

const DirectorySelector = ({
  directories,
  onSelect,
  value,
}: DirectorySelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const dicMap = useMemo(() => {
    const map = new Map<string, Directory>();
    directories.forEach((d) => {
      map.set(d.id, d);
    });
    return map;
  }, [directories]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (d: Directory) => {
    onSelect(d.id);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="inherit"
        variant="outlined"
      >
        {dicMap.get(value || "")?.name || "Select a directory"}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {directories
          .sort((a, b) => sort(a.name, b.name))
          .map((d) => (
            <MenuItem onClick={() => handleClose(d)} key={d.id} value={d.id}>
              {d.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const sort = (a: string, b: string) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};
