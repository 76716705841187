import * as ReactDOM from "react-dom";

import { App } from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithHistory } from "./auth";

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
);
