import { Button, Grid, Typography } from "@mui/material";

import { Lock } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

export const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return <Navigate to={"/"}></Navigate>;
  }
  return (
    <Grid container spacing={0} direction="column" alignItems="center">
      <Lock />
      <Typography variant="h4">Not Logged In</Typography>
      <Typography variant="subtitle1">Please login first.</Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          loginWithRedirect();
        }}
      >
        Login
      </Button>
    </Grid>
  );
};
