import { useState } from "react";

import { User } from "../api/types";
import { useInviteUser } from "../api";

import Alert from "@mui/material/Alert";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";

interface Props {
  directory: string | null;
  isOpen: boolean;
  close: (u: User | null) => void;
}

export const InviteUserDialog = (props: Props) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, error, inviteUser] = useInviteUser();

  const invite = async () => {
    if (props.directory !== null) {
      var user = await inviteUser(props.directory, emailAddress);
      if (user !== null) {
        props.close(user);
      }
    }
  };

  return (
    <Dialog open={props.isOpen} onClose={() => props.close(null)}>
      {loading && <LinearProgress />}
      <DialogTitle>Invite User</DialogTitle>
      {error !== null && <Alert severity="error">{error}</Alert>}
      <DialogContent>
        <DialogContentText>
          Invited user will be able to use the full mobile application and will
          be invited by email.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          onChange={(e) => setEmailAddress(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.close(null)} color="primary">
          Cancel
        </Button>
        <Button onClick={invite} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
