import { useState, useEffect, useCallback, useRef } from "react";

import { InviteUserDialog } from "./InviteUser";
import {
  useFetchUsers,
  useApproveUser,
  useUnapproveUser,
  useRemoveUser,
} from "../api";
import { User } from "../api/types";

import {
  Cancel,
  CheckCircle,
  Delete,
  MoreVert,
  Refresh,
  Search,
} from "@mui/icons-material";

import Alert from "@mui/material/Alert";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "./loading";

const isUserApproved = (u: User) => u.approved_at !== "0001-01-01T00:00:00Z";

const formatTime = (approved_at: string) => {
  var d = new Date(approved_at);
  return d.toLocaleString();
};

const sortByEmail = (a: User, b: User) => {
  if (a.email_address < b.email_address) {
    return -1;
  }
  if (a.email_address > b.email_address) {
    return 1;
  }
  return 0;
};

interface Props {
  directory: string | null;
}

export const UsersList = withAuthenticationRequired(
  (props: Props) => {
    const [users, setUsers] = useState<User[]>([]);

    const [successMessage, setSuccessMessage] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isAddUserOpen, setIsAddUserOpen] = useState(false);

    const [loadingUsers, usersError, loadedUsers, loadUsers] = useFetchUsers();
    const [loadingInvite, approveError, , approveUser] = useApproveUser();
    const [, unapproveError, , unapproveUser] = useUnapproveUser();
    const [, removeError, removeUser] = useRemoveUser();

    const directory = props.directory;

    const load = useCallback(() => {
      if (directory !== null && directory !== "") {
        loadUsers(directory);
      }
    }, [directory, loadUsers]);

    useEffect(() => {
      // Load users when directory changes.
      setUsers([]);
      load();
    }, [directory, load, loadUsers]);

    useEffect(() => {
      setUsers(loadedUsers);
    }, [loadedUsers]);

    const inviteUserCallback = useCallback((u: User | null) => {
      if (u !== null) {
        setUsers((users) => {
          users.push(u);
          return users;
        });
        setSuccessMessage("User created.");
      }
      setIsAddUserOpen(false);
    }, []);

    const approve = async (u: User) => {
      if (props.directory !== null) {
        const approvedUser = await approveUser(props.directory, u);
        if (approvedUser !== null) {
          var index = users.findIndex((user) => user.key === approvedUser.key);
          users[index] = approvedUser;
          setUsers(users);
          setSuccessMessage("User approved.");
        }
      }
    };

    const remove = async (u: User) => {
      if (props.directory !== null) {
        await removeUser(props.directory, u);

        var index = users.findIndex((user) => user.key === u.key);
        if (index >= 0) {
          delete users[index];
          setUsers(users);
          setSuccessMessage("User removed.");
        }
      }
    };

    const unapprove = async (u: User) => {
      if (props.directory !== null) {
        const unapprovedUser = await unapproveUser(props.directory, u);
        if (unapprovedUser !== null) {
          var index = users.findIndex(
            (user) => user.key === unapprovedUser.key
          );
          users[index] = unapprovedUser;
          setUsers(users);
          setSuccessMessage("User unapproved.");
        }
      }
    };

    const isUserNotFound = users.length === 0;

    return (
      <>
        {(usersError || approveError || unapproveError || removeError) && (
          <Alert severity="error">
            {usersError || approveError || unapproveError || removeError}
          </Alert>
        )}
        <Snackbar
          open={successMessage !== ""}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={() => setSuccessMessage("")}
        >
          <Alert onClose={() => setSuccessMessage("")} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
        <Box>
          <Paper sx={{ margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              color="default"
              elevation={0}
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Search color="inherit" sx={{ display: "block" }} />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      placeholder="Search by email address"
                      InputProps={{
                        disableUnderline: true,
                        sx: { fontSize: "default" },
                      }}
                      variant="standard"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ mr: 1 }}
                      onClick={() => setIsAddUserOpen(true)}
                    >
                      Add user
                    </Button>
                    <Tooltip title="Reload">
                      <IconButton onClick={() => load()}>
                        <Refresh color="inherit" sx={{ display: "block" }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>

            <TableContainer sx={{ minWidth: 800 }}>
              {(loadingUsers || loadingInvite) && <LinearProgress />}

              <Table>
                <TableBody>
                  {users
                    .filter((u) => u.email_address.includes(searchTerm))
                    .sort(sortByEmail)
                    .map((user) => {
                      const { key, email_address, approved_at, approved_by } =
                        user;

                      return (
                        <TableRow hover key={key} tabIndex={-1}>
                          <TableCell>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              {isUserApproved(user) ? (
                                <CheckCircle color="success" />
                              ) : (
                                <CheckCircle color="error" />
                              )}
                              <Avatar {...stringAvatar(email_address)} />
                              <Typography variant="subtitle2" noWrap>
                                {email_address}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell>
                            {isUserApproved(user) ? (
                              <Tooltip
                                arrow
                                title={"Approved by " + approved_by}
                              >
                                <span>{formatTime(approved_at)}</span>
                              </Tooltip>
                            ) : (
                              <span></span>
                            )}
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu
                              approved={isUserApproved(user)}
                              remove={() => {
                                remove(user);
                              }}
                              approve={() => {
                                approve(user);
                              }}
                              deactivate={() => {
                                unapprove(user);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography
                          sx={{ my: 5, mx: 2 }}
                          color="text.secondary"
                          align="center"
                        >
                          No users for this tenant yet
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <InviteUserDialog
          directory={props.directory}
          isOpen={isAddUserOpen}
          close={inviteUserCallback}
        ></InviteUserDialog>
      </>
    );
  },
  { onRedirecting: () => <Loading></Loading> }
);

interface MoreMenuProps {
  approved: boolean;
  remove: () => void;
  approve: () => void;
  deactivate: () => void;
}

const MoreMenu = ({ approved, remove, approve, deactivate }: MoreMenuProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVert></MoreVert>
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {!approved ? (
          <MenuItem onClick={() => approve()}>
            <ListItemIcon>
              <CheckCircle></CheckCircle>
            </ListItemIcon>
            <ListItemText
              primary="Approve"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          <MenuItem onClick={() => deactivate()}>
            <ListItemIcon>
              <Cancel></Cancel>
            </ListItemIcon>
            <ListItemText
              primary="Deactivate"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <MenuItem onClick={() => remove()}>
          <ListItemIcon>
            <Delete></Delete>
          </ListItemIcon>
          <ListItemText
            primary="Remove"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

function stringAvatar(name: string) {
  return {
    children: `${name.split(".")[0][0]}${name.split(".")[1][0]}`.toUpperCase(),
  };
}
