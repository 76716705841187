import { useState, useEffect, useCallback, useMemo, useRef } from "react";

import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  User,
} from "firebase/auth";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { ContentCopy, Google } from "@mui/icons-material";
import { Loading } from "./loading";

const CodeBreak = styled("code")(({ theme }) => ({
  marginRight: theme.spacing(2),
  wordBreak: "break-word",
}));

const TokenContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#f5f2f0",
  padding: 16,
  marginBottom: 10,
}));

const Offset = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const ButtonGroup = styled("div")(({ theme }) => ({
  display: "flex",
}));

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_WEB_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};

export const Token = withAuthenticationRequired(
  () => {
    const [token, setToken] = useState("");
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const TokenContainerRef = useRef<HTMLDivElement>(null);

    const auth = useMemo(() => {
      initializeApp(firebaseConfig);
      const auth = getAuth();
      auth.tenantId = process.env.REACT_APP_FIREBASE_TENANT || null;
      return auth;
    }, []);

    const signIn = useCallback(() => {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider);
    }, [auth]);

    useEffect(() => {
      setLoading(true);
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
          user
            ?.getIdToken()
            .then((token) => {
              setToken(token);
              setLoading(false);
            })
            .catch((e) => {
              console.error("failed to get token", e);
              setToken("");
              setLoading(false);
            });
        } else {
          setUser(null);
          setToken("");
          setLoading(false);
        }
      });
    }, [auth]);

    const onCopyButtonClick = () => {
      if (TokenContainerRef.current != null) {
        navigator.clipboard
          .writeText(TokenContainerRef.current.textContent || "")
          .then(
            () => {
              console.log("Async: Copying to clipboard was successful!");
            },
            (err) => {
              console.error("Async: Could not copy text: ", err);
            }
          );
      }
    };

    if (user == null) {
      return (
        <Paper sx={{ margin: "auto", overflow: "hidden" }}>
          {loading && <LinearProgress />}
          <Box sx={{ px: 3, py: 3, textAlign: "center" }}>
            <Typography variant="h4" gutterBottom component="div">
              Generate DEV Token
            </Typography>
            <Typography variant="body1" gutterBottom component="div">
              Create a token to use with moby gateway enddpoints
            </Typography>
            <Button
              variant="contained"
              startIcon={<Google />}
              onClick={signIn}
              disabled={loading}
            >
              Sign in with Google
            </Button>
          </Box>
        </Paper>
      );
    }

    return (
      <Paper sx={{ margin: "auto", overflow: "hidden" }}>
        <Box sx={{ px: 3, py: 3 }}>
          <Typography variant="h4" gutterBottom component="div">
            {user.email}
          </Typography>
          <TokenContainer>
            <CodeBreak ref={TokenContainerRef}>{token}</CodeBreak>
          </TokenContainer>
          <ButtonGroup>
            <Button
              variant="contained"
              startIcon={<ContentCopy></ContentCopy>}
              onClick={onCopyButtonClick}
            >
              Copy
            </Button>
            <Offset />
            <Button
              variant="contained"
              onClick={() => {
                auth.signOut();
              }}
            >
              Genereate new Token
            </Button>
          </ButtonGroup>
        </Box>
      </Paper>
    );
  },
  { onRedirecting: () => <Loading></Loading> }
);
