import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useCallback } from "react";
import { Directory, User } from "./types";

type Fetcher = (
  path: string,
  method: string,
  body: Object | null
) => Object | null;

export const useAPI = (): [
  boolean,
  string | null,
  Object | undefined,
  Fetcher
] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<Object>();
  const { getAccessTokenSilently } = useAuth0();

  const fetcher = useCallback(
    async (
      path: string,
      method: string,
      body: Object | null
    ): Promise<Object | null> => {
      setLoading(true);

      let b: string | null = null;
      if (body !== null) {
        b = JSON.stringify(body);
      }
      let url = process.env.REACT_APP_USER_SERVICE_URL + path;
      const token = await getAccessTokenSilently();
      const response = await fetch(url, {
        method: method,
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: b,
      });

      var data = await response.json();

      if (response.ok === false) {
        if (data["message"]) {
          setError(data["message"]);
        } else {
          setError("request failed with status code: " + response.status);
        }

        setResponse(undefined);
        setLoading(false);
        return null;
      }

      setError(null);
      setResponse(data);
      setLoading(false);
      return data;
    },
    [getAccessTokenSilently]
  );

  return [loading, error, response, fetcher];
};

export const useFetchUsers = (): [
  boolean,
  string | null,
  User[],
  (directory: string) => void
] => {
  const [loading, error, response, fetcher] = useAPI();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (loading === false && response != null) {
      setUsers(response as User[]);
    }
  }, [loading, response]);

  const load = useCallback(
    (directory: string) => {
      setUsers([]);
      fetcher("/v1/directory/" + directory + "/user/", "GET", null);
    },
    [fetcher]
  );

  return [loading, error, users, load];
};

export const useApproveUser = (): [
  boolean,
  string | null,
  User | null,
  (directory: string, u: User) => Promise<User | null>
] => {
  const [loading, error, response, fetcher] = useAPI();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (loading === false && response != null) {
      setUser(response as User);
    }
  }, [loading, response]);

  const approve = async (directory: string, u: User): Promise<User | null> => {
    let data = await fetcher(
      "/v1/directory/" + directory + "/user/" + u.key + "/approve",
      "PATCH",
      null
    );
    return data as User;
  };

  return [loading, error, user, approve];
};

export const useUnapproveUser = (): [
  boolean,
  string | null,
  User | null,
  (directory: string, u: User) => Promise<User | null>
] => {
  const [loading, error, response, fetcher] = useAPI();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (loading === false && response != null) {
      setUser(response as User);
    }
  }, [loading, response]);

  const unapprove = async (
    directory: string,
    u: User
  ): Promise<User | null> => {
    let data = await fetcher(
      "/v1/directory/" + directory + "/user/" + u.key + "/unapprove",
      "PATCH",
      null
    );
    return data as User;
  };

  return [loading, error, user, unapprove];
};

export const useRemoveUser = (): [
  boolean,
  string | null,
  (directory: string, u: User) => Promise<Object | null>
] => {
  const [loading, error, , fetcher] = useAPI();

  const remove = async (directory: string, u: User): Promise<Object | null> => {
    var data = await fetcher(
      "/v1/directory/" + directory + "/user/" + u.key,
      "DELETE",
      null
    );
    return data;
  };

  return [loading, error, remove];
};

export const useInviteUser = (): [
  boolean,
  string | null,
  (directory: string, email_address: string) => Promise<User | null>
] => {
  const [loading, error, , fetcher] = useAPI();

  const invite = async (
    directory: string,
    email_address: string
  ): Promise<User | null> => {
    var data = await fetcher(
      "/v1/directory/" + directory + "/user/?invite=true",
      "POST",
      { email_address: email_address }
    );
    return data as User;
  };

  return [loading, error, invite];
};

export const useDirectory = (): [
  boolean,
  string | null,
  Array<Directory>,
  () => void
] => {
  const [loading, error, response, fetcher] = useAPI();
  const [directories, setDirectories] = useState<Array<Directory>>([]);

  useEffect(() => {
    if (loading === false && response != null) {
      setDirectories(response as Array<Directory>);
    }
  }, [loading, response]);

  const load = useCallback(() => {
    fetcher("/v1/directory", "GET", null);
  }, [fetcher]);

  return [loading, error, directories, load];
};
