import { CircularProgress } from "@mui/material";

export const Loading = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress size={100} />
  </div>
);
