import React from "react";
import { useNavigate } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

const providerConfig = {
  domain: "volumental.eu.auth0.com",
  clientId: "EIVszuIou05Pbp8zYYZ66T1I5Np7aFSk", // "user-admin-ui-spa"
  redirectUri: window.location.origin,
  audience: "https://internal.volumental.com",
  responseType: "token id_token",
  scope: "openid",
};

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider {...providerConfig} onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};
