import { useState, useEffect } from "react";

import { TopBar } from "./TopBar";
import { UsersList } from "./UsersList";

import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import { Token } from "./Token";

interface Props {}

export const App = (props: Props) => {
  const [directory, setDirectory] = useState(localStorage.getItem("directory"));

  useEffect(() => {
    // Remember the selected directory for next login or page refresh.
    if (directory !== null) {
      localStorage.setItem("directory", directory);
    }
  }, [directory]);

  return (
    <div className="app-root">
      <TopBar directory={directory} setDirectory={setDirectory} />
      <Container maxWidth="lg" style={{ marginTop: "30px" }}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<UsersList directory={directory} />} />
          <Route path="/developer" element={<Token />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Container>
    </div>
  );
};
